<template>
  <div class="flex-col-center-center w-full">
    <div class="form-group last" v-if="design == 'normal'">
      <label class="form-label-inline">2 - {{ $t("wizard.inputCredentials") }} <LabelRequired /></label>
      <label class="form-label-help">{{ $t("wizard.inputCredentialsDetails") }}</label>
    </div>
    <div v-else class="mt-2 form-group">
      <Message severity="info" :closable="false" icon="pi pi-info-circle">
        <a :href="linkToDocs" target="_blank" class="underline">{{ $t("wizard.stepByStepForCreatignTheTokens") }}</a>
      </Message>
    </div>
    <div class="w-full border rounded p-3">
      <div v-for="(input, index) in inputs.filter((f) => f.type != 'hidden')" :key="index" class="form-group" :class="{'flex': input.type == 'checkbox'}">
        <label class="form-label-inline capitalize" :for="input.name">{{ input.name }} <LabelRequired v-if="input.validation && input.validation.length && input.validation.includes('required')" /></label>
        <Skeleton v-if="input.loading" width="100%" height="2.5rem" />
        <div class="flex justify-between space-x-2" v-if="!input.loading">
          <input v-if="!input.loading && !['select', 'multiselect'].includes(input.type)" :id="input.name" :type="input.type" :placeholder="input.name" :class="{ 'dl-input text-sm': input.type !== 'checkbox', 'ml-2': input.type == 'checkbox' }" :name="input.name" v-model="input.value" v-debounce:700ms="searchOptions" />
          <Dropdown v-if="input.type == 'select'" v-model="input.value" :options="input.options" :optionLabel="input.label" :optionValue="input.keyValue" :dataKey="input.keyValue" :disabled="!input.options.length" :editable="input.editable" :showClear="true" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full" />
          <MultiSelect v-if="input.type == 'multiselect'" v-model="input.value" :options="input.options" :optionLabel="input.label" :optionValue="input.keyValue" :disabled="!input.options.length" :filter="true" :maxSelectedLabels="1" :selectedItemsLabel="`{0} ${$t(`wizard.inputsCustom.${input.name}_selected`)}`" :placeholder="$t(`wizard.inputsCustom.${input.name}`)" class="p-inputtext-sm shadow-sm w-full" />
          <Button v-if="input.buttonReload" @click="reloadOptions(index)" type="button" class="p-button-sm p-button-light">
            <Icon icon="tabler:reload" class="w-6 h-6" />
          </Button>
          <Button v-if="input.name == 'token' && input?.extra?.generateToken" @click="generateToken(input)" type="button" class="p-button-sm p-button-light" v-tooltip.top="'Generate token'">
            <Icon icon="tabler:password" class="w-6 h-6" />
          </Button>
        </div>
      </div>
    </div>

    <!-- Custom validation for credentials -->
    <div v-if="error" class="w-full flex-center justify-start">
      <span class="mt-1 text-sm text-red-600">{{ $t("The credentials are missing.") }}</span>
    </div>
  </div>
</template>

<script>
// App components
import LabelRequired from "@/components/LabelRequired"
import Dropdown from "primevue/dropdown"
import axiosClient from "@/config/AxiosClient"
import Skeleton from "primevue/skeleton"
import Button from "primevue/button"
import Message from "primevue/message"
import { Icon } from "@iconify/vue"
import MultiSelect from "primevue/multiselect"

export default {
  name: "TypeForm",

  components: {
    LabelRequired,
    Dropdown,
    Skeleton,
    Button,
    Icon,
    MultiSelect,
    Message
  },

  props: {
    channel: Object,
    design: {
      type: String,
      default: "normal"
    }
  },

  computed: {
    linkToDocs() {
      return this.documentationTokens[this.channel.name] || "https://help.setup.baymark.net/es/docs/conectando-canales"
    }
  },

  data() {
    return {
      inputs: [],
      error: false,
      documentationTokens: {
        Vtex: "https://help.setup.baymark.net/es/docs/vtex-crear-credenciales-de-acceso",
        Magento1: "https://help.setup.baymark.net/es/docs/magento-1-crear-credenciales-de-acceso",
        Magento2: "https://help.setup.baymark.net/es/docs/magento-2-crear-credenciales-de-acceso",
        Prestashop: "https://help.setup.baymark.net/es/docs/prestashop-crear-credenciales-de-acceso",
        "Tienda Nube": "https://help.setup.baymark.net/es/docs/tiendanube-crear-credenciales-de-acceso",
        WooCommerce: "https://help.setup.baymark.net/es/docs/woocommerce-crear-credenciales-de-acceso",
        Shopify: "https://help.setup.baymark.net/es/docs/shopify-crear-credenciales-de-acceso",
        Simbel: "https://help.setup.baymark.net/es/docs/simbel-crear-credenciales-de-acceso",
        Fenicio: "https://help.setup.baymark.net/es/docs/fenicio-crear-credenciales-de-acceso",
        "Salesforce Commerce": "https://help.setup.baymark.net/es/docs/salesforce-commerce-crear-credenciales-de-acceso",
        MercadoShops: "https://help.setup.baymark.net/es/docs/mercadoshops-crear-credenciales-de-acceso",
        Hubspot: "https://help.setup.baymark.net/es/docs/hubspot-crear-credenciales-de-acceso",
        "Salesforce CRM": "https://help.setup.baymark.net/es/docs/salesforce-crm-crear-credenciales-de-acceso",
        ZohoCRM: "https://help.setup.baymark.net/es/docs/zoho-crm-crear-credenciales-de-acceso",
        WebHook: "https://help.setup.baymark.net/es/docs/webhook-crear-credenciales-de-acceso",
        MailUp: "https://help.setup.baymark.net/es/docs/mailup-crear-credenciales-de-acceso",
        Acumbamail: "https://help.setup.baymark.net/es/docs/acumbamail-crear-credenciales-de-acceso",
        SetUp: "https://help.setup.baymark.net/es/docs/setup-crear-credenciales-de-acceso",
        "Social WiFi": "https://help.setup.baymark.net/es/docs/social-wifi-crear-credenciales-de-acceso"
      },
    }
  },

  created() {
    let inputs = JSON.parse(JSON.stringify(this.channel.fields))
    this.inputs = inputs.map((input) => {
      if (input.value === undefined) input.value = null
      if (["select", "multiselect"].includes(input.type) && !input.options) {
        input.options = []
        input.buttonReload = true
      }
      input.loading = false
      return input
    })
    this.searchOptions()
  },

  methods: {
    sendCredential() {
      let data = {}
      this.error = false

      for (let input of this.inputs) {
        if (input.validation && input.validation.length && input.validation.includes("required") && (input.value == undefined || input.value == "")) {
          this.error = true
        }

        if (input.value !== null && input.value !== '') {
          data[input.name] = input.value
        }
      }

      this.$emit("saveCredential", data, this.error)
    },
    async searchOptions() {
      for (const [i, input] of this.inputs.entries()) {
        if (["select", "multiselect"].includes(input.type) && !input.options.length) {
          let validation = this.inputs.filter((f) => input.credentials.includes(f.name)).every((f) => f.value != null && f.value != "")
          if (validation) {
            this.inputs[i].loading = true
            let config = this.inputs
              .reduce((acc, val) => {
                let valReturn = { ...acc }
                if(val.value != null && val.value != ""){
                  valReturn[val.name] = val.value
                }
                return valReturn
              }, {})
            let functionOptions = input?.function ?? input.name
            let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${this.channel.id}/external/data/${functionOptions}`, {
              config
            })
            this.inputs[i].options = res.data
            if(res.data.length == 1){
              this.inputs[i].value = res.data[0][input.keyValue]
            }
            this.inputs[i].loading = false
          }
        }
      }
    },
    async reloadOptions(inputIndex) {
      let validation = this.inputs.filter((f) => this.inputs[inputIndex].credentials.includes(f.name)).every((f) => f.value != null && f.value != "")
      if (validation) {
        this.inputs[inputIndex].loading = true
        let config = this.inputs
          .reduce((acc, val) => {
            let valReturn = { ...acc }
            valReturn[val.name] = val.value
            return valReturn
          }, {})
        let functionOptions = this.inputs[inputIndex]?.function ?? this.inputs[inputIndex].name
        let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${this.channel.id}/external/data/${functionOptions}`, {
          config
        })
        this.inputs[inputIndex].options = res.data
        this.inputs[inputIndex].loading = false
      }
    },

    generateToken(input) {
      input.value = this.createHash(32)
    },

    createHash(longitud) {
      let caracteres = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_"
      let password = ""
      for (let i = 0; i < longitud; i++) {
        password += caracteres.charAt(Math.floor(Math.random() * caracteres.length))
      }
      return password
    }
  }
}
</script>
