<template>
  <div class="w-full mx-auto sm:max-w-xl my-5">
    <!-- Loading information -->
    <Card v-if="loadings.general">
      <template #content>
        <p class="font-normal text-gray-600">{{ $t("Loading information...") }}</p>
      </template>
    </Card>
    <!-- Show integrator -->
    <div v-else>
      <BlockUI :blocked="loadings.credentialTo" :baseZIndex="100" :autoZIndex="false">
        <BlockUILoader v-if="loadings.credentialTo" :text="$t('wizard.validating')" />
        <Card v-if="requireCredentials">
          <template #content>
            <div class="flex flex-col space-y-3">
              <div class="flex-center space-x-3 mb-2">
                <img :src="channelTo.avatar_short" class="w-10 h-10 object-contain" />
                <span class="flex items-center text-base text-gray-500">{{ channelTo.name }}</span>
              </div>
              <div class="flex flex-col">
                <TypeForm v-if="channelTo.type_field == 'form'" ref="credentialComponent" :channel="channelTo" design="compact" @saveCredential="validateCredential" />
                <TypeOAuth2 v-else :hiddenInputs="hiddenInputs" ref="credentialComponent" :channel="channelTo" design="compact" @saveCredential="validateCredential" />
              </div>
              <div class="flex justify-end" v-if="!hiddenInputs || channelTo.type_field == 'form'">
                <Button type="button" @click="sendCredential" :loading="loadings.general" :label="$t('integrations.formIntegration.buttonSave')" iconPos="right" class="p-button-primary shadow-md" />
              </div>
            </div>
          </template>
        </Card>
      </BlockUI>
      <!-- Form services -->
      <Card v-if="!requireCredentials && channelFrom.services && channelTo.services_output && (channelTo.id != 7 || channelFrom.services.filter((s) => s.config.canPremium === true).length)">
        <template #content>
          <div class="flex flex-col space-y-3">
            <div class="flex-center space-x-3 mb-2">
              <img :src="channelTo.avatar_short" class="w-10 h-10 object-contain" />
              <span class="flex items-center text-base text-gray-500">{{ channelTo.name }}</span>
            </div>
            <div v-if="!showButtonSave">
              <AutoMapperSkeleton />
            </div>
            <div v-else>
              <AutoMapper ref="automapper" :projectCredentialFromId="projectCredentialFrom.id" :projectCredentialToId="projectCredentialTo.id" :servicesFrom="channelFrom.services" :serviceTo="channelTo.services_output[0]" :integrations="getIntegrations" @dataFinish="finishIntegration" @deleteIntegration="onDeleteIntegration" :iframe="true" />
            </div>
          </div>
        </template>
      </Card>
      <div v-if="showButtonSave" class="w-full max-w-3xl mt-3 flex justify-end items-center">
        <div class="flex-center space-x-3">
          <p v-if="loadings.integrationSaved">{{ $t("saved") }}.</p>
          <Button type="button" @click="createIntegration" :loading="loadings.saveIntegration" :label="$t('integrations.formIntegration.buttonSave')" iconPos="right" class="p-button-primary shadow-md" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosClient from "@/config/AxiosClient"
import TypeForm from "@/components/ChannelConnections/TypeForm.vue"
import TypeOAuth2 from "@/components/ChannelConnections/TypeOAuth2.vue"
import AutoMapper from "@/components/WizardNew/AutoMapper"
import Card from "primevue/card"
import Button from "primevue/button"
import BlockUI from "primevue/blockui"
import BlockUILoader from "@/components/UI/BlockUILoader"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import AutoMapperSkeleton from "@/components/Integrations/Wizard/AutoMapperSkeleton"

export default {
  name: "Iframe",

  components: {
    TypeForm,
    TypeOAuth2,
    Card,
    Button,
    AutoMapper,
    BlockUI,
    BlockUILoader,
    AutoMapperSkeleton
  },

  data() {
    return {
      selectDataTo: {},
      channelFrom: {},
      channelTo: {},
      projectCredentialFrom: {},
      projectCredentialTo: {},
      projectConnection: {},
      requireCredentials: false,
      showButtonSave: false,
      hiddenInputs: true,
      loadings: {
        general: true,
        credentialTo: false,
        automapper: false,
        saveIntegration: false,
        integrationSaved: false
      },
      accessToken: null,
      credentials: {},
      project: {},
      deleteIntegration: []
    }
  },

  computed: {
    getIntegrations(){
      return this.projectConnection.integrations ?? []
    }
  },

  created() {
    this.login()

    if (this.$route.query.language) {
      this.$store.dispatch("Langs/changeAppLang", this.$route.query.language)
      this.$i18n.locale = this.$store.state.Langs.selected.lang
    }
  },

  methods: {
    async login() {
      try {
        let res = await axiosClient.post(`/api/2.0/auth/login`, { email: this.$route.query.email, password: this.$route.query.password })
        this.accessToken = res.data.access_token
        await this.$store.dispatch("Auth/setToken", res.data.access_token)
        let resUser = await new FormClient({ include: "projects.project_credentials" }).get(ApiRoute.auth.profile)
        if (resUser.data.data.projects[0] && resUser.data.data.projects[0].project_credentials.find((f) => f.channel_id == this.$route.query.driver_to)) {
          this.projectCredentialFrom = resUser.data.data.projects[0].project_credentials.find((f) => f.channel_id == this.$route.query.driver_from)
          this.projectCredentialTo = resUser.data.data.projects[0].project_credentials.find((f) => f.channel_id == this.$route.query.driver_to)
          this.project = resUser.data.data.projects[0]
          await this.searchChannels()
          this.requireCredentials = false
          let resProjectConnection = await axiosClient.get("/api/2.0/project/connection")
          if (resProjectConnection.data.data[0]) {
            this.projectConnection = resProjectConnection.data.data[0]
          }
          this.showButtonSave = true
        } else {
          let res = await axiosClient.get(`/api/2.0/channel/public/${this.$route.query.driver_to}`)
          this.channelTo = res.data
          if (resUser.data.data.projects[0]) {
            this.hiddenInputs = false
            this.project = resUser.data.data.projects[0]
          }
          this.requireCredentials = true
        }
      } catch (error) {
        let res = await axiosClient.get(`/api/2.0/channel/public/${this.$route.query.driver_to}`)
        this.channelTo = res.data
        this.requireCredentials = true
      }
      this.loadings.general = false
    },

    async validateCredential(data, error) {
      if(error) return false
      this.loadings.credentialTo = true
      try{
        if (!this.accessToken) {
          let res = await axiosClient.post(`/api/2.0/auth/user/app/${this.$route.query.driver_to}`, { name: this.$route.query.email, email: this.$route.query.email, password: this.$route.query.password, credentials: data })
          this.accessToken = res.data.access_token
          await this.$store.dispatch("Auth/setToken", this.accessToken)
          let resUser = await new FormClient({ include: "projects.project_credentials" }).get(ApiRoute.auth.profile)
          this.project = resUser.data.data.projects[0]
          if (this.channelTo.type_field == "oAuth2") {
            if (this.channelTo.fields.length) this.$refs.credentialComponent.searchOptions()
          }else{
            await this.createCredential(data)
          }
          this.hiddenInputs = false
        } else {
          await this.createCredential(data)
        }
      }catch(err){
        this.$toast.success(this.$t("wizard.invalidCredential"))
      }
      this.loadings.credentialTo = false
    },

    async createCredential(data){
      let credentialsFrom = JSON.parse(JSON.stringify(this.$route.query))
      delete credentialsFrom.driver_to
      delete credentialsFrom.driver_from
      delete credentialsFrom.email
      delete credentialsFrom.password
      let resCredentialFrom = await axiosClient.post(`/api/2.0/project/${this.project.id}/channel/${this.$route.query.driver_from}/credentials`, {
        name: this.channelFrom.name,
        config: credentialsFrom
      })
      this.projectCredentialFrom = resCredentialFrom.data
      let resCredentialTo = await axiosClient.post(`/api/2.0/project/${this.project.id}/channel/${this.$route.query.driver_to}/credentials`, {
        name: this.channelTo.name,
        config: data
      })
      this.projectCredentialTo = resCredentialTo.data
      await this.searchChannels()
      this.showButtonSave = true
      this.requireCredentials = false
      if (resCredentialTo.status == 200 || resCredentialTo.status == 204) {
        this.$toast.success(this.$t("connections.formConnection.alertSuccess"))
      } else {
        this.$toast.error(this.$t("connections.formConnection.alertError"))
      }
    },

    sendCredential() {
      this.$refs.credentialComponent.sendCredential()
    },

    async searchChannels() {
      let resChannelFrom = await axiosClient.get(`/api/2.0/channels/${this.$route.query.driver_from}`)
      this.channelFrom = resChannelFrom.data.data
      let resChannelTo = await axiosClient.get(`/api/2.0/channels/${this.$route.query.driver_to}`)
      this.channelTo = resChannelTo.data.data
    },

    async createIntegration() {
      this.$refs.automapper.sendDataFinish()
    },

    async finishIntegration(data) {
      if (data.error) {
        return
      }

      this.loadings.saveIntegration = true

      if (!this.projectConnection.id) {
        let responseProjectConnection = await axiosClient.post(`api/2.0/integrations`, {
          project_id: this.project.id,
          project_credential_from_id: this.projectCredentialFrom.id,
          project_credential_to_id: this.projectCredentialTo.id
        })
        this.projectConnection = responseProjectConnection.data.data
      }

      await axiosClient.put(`/api/2.0/integrations/${this.projectConnection.id}/automapper`, { data, deleteIntegration: this.deleteIntegration })
      let resProjectConnection = await axiosClient.get("/api/2.0/project/connection")
      this.projectConnection = resProjectConnection.data.data[0]
      this.$refs.automapper.injectDataIntegration(this.projectConnection.integrations)

      this.loadings.saveIntegration = false

      this.loadings.integrationSaved = true
      setTimeout(() => {
        this.loadings.integrationSaved = false
      }, 2000)
    },

    async onDeleteIntegration(service) {
      if (service.integration_id) {
        this.deleteIntegration.push(service.integration_id)
      }
    }
  }
}
</script>
