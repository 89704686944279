<template>
  <div class="w-full space-y-3">
    <!-- OAuth 2 -->
    <div v-if="!hiddenInputWithValue" class="form-group">
      <label class="form-label" v-if="design == 'normal'">2 - {{ $t("wizard.generate access") }} <LabelRequired /></label>
      <div class="rounded border p-2">
        <div v-if="!isOAuth2Connected" class="flex-center justify-between">
          <div class="flex-center space-x-2">
            <img :src="channel.avatar_short" class="w-8 h-8" />
            <span class="form-label-inline">{{ $t("wizard.connectToX", { name: channel.name }) }}</span>
          </div>
          <Button @click="redirect" type="button" :label="$t('wizard.generate access login')" icon="pi pi-external-link" iconPos="right" class="p-button-success shadow" />
        </div>
        <div v-else class="flex-center justify-between">
          <div class="flex-center space-x-2">
            <img :src="channel.avatar_short" class="w-8 h-8" />
            <span class="form-label-inline">{{ $t("wizard.connectedToX", { name: channel.name }) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Dynamic fields -->
    <div v-if="isOAuth2Connected && !hiddenInputs">
      <div v-for="(input, i) of inputs" :key="i" class="w-full">
        <div class="form-group">
          <label class="form-label uppercase" v-if="design == 'normal'">{{ i + 3 }} - {{ input.name }} <LabelRequired v-if="input.validation && input.validation.length && input.validation.includes('required')" /></label>
          <!-- Skeleton -->
          <Skeleton v-if="input.loading" width="100%" height="2.5rem" />
          <!-- Form -->
          <div v-if="!input.loading">
            <InputText v-if="!['select', 'multiselect'].includes(input.type)" v-model="input.value" :type="input.type" :name="input.name" :placeholder="$t(`wizard.inputsCustom.${input.name}`)" />
            <div class="flex justify-between" v-else>
              <Dropdown v-if="input.type == 'select'" v-model="input.value" :name="input.name" :options="input.options" :optionLabel="input.label" :optionValue="input.keyValue" :dataKey="input.keyValue" :disabled="!input.options.length" :placeholder="$t(`wizard.inputsCustom.${input.name}`)" class="p-inputtext-sm shadow-sm w-full" :showClear="true" />
              <MultiSelect v-if="input.type == 'multiselect'" v-model="input.value" :options="input.options" :optionLabel="input.label" :optionValue="input.keyValue" :disabled="!input.options.length" :filter="true" :placeholder="$t(`wizard.inputsCustom.${input.name}`)" class="p-inputtext-sm shadow-sm w-full" :showClear="true"/>
              <Button v-if="input.buttonReload" @click="reloadOptions(i)" type="button" class="p-button-sm p-button-light flex-center space-x-2 ml-2">
                <Icon icon="tabler:reload" class="w-6 h-6" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!-- Custom validation for credentials -->
    </div>

    <!-- Custom validation for credentials -->
    <div v-if="error" class="w-full flex-center justify-start">
      <span class="mt-1 text-sm text-red-600">{{ $t("The credentials are missing.") }}</span>
    </div>
  </div>
</template>

<script>
// Framework components
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import { Icon } from "@iconify/vue"
import axiosClient from "@/config/AxiosClient"
// App components
import LabelRequired from "@/components/LabelRequired"
import MultiSelect from 'primevue/multiselect';

export default {
  name: "TypeOAuth2",

  components: {
    Button,
    Skeleton,
    Dropdown,
    InputText,
    LabelRequired,
    Icon,
    MultiSelect
  },
  props: {
    projectCredential: {
      type: Object,
      default: function () {
        return {}
      }
    },
    channel: Object,
    hiddenInputWithValue: Boolean,
    hiddenInputs: {
      type: Boolean,
      default: false
    },
    design: {
      type: String,
      default: "normal"
    }
  },

  data() {
    return {
      credentials: {},
      inputs: [],
      isOAuth2Connected: false,
      error: false
    }
  },

  computed: {
    getProjectActive() {
      return this.$store.state.Projects.activeProject
    }
  },

  created() {
    let fields = this.channel.fields.filter(f => !f.notShowV1) ?? []
    fields.forEach((input) => {
      if (!Object.keys(this.projectCredential).length) {
        input.value = null
      } else {
        input.value = this.projectCredential.config[input.name] ?? null
      }
      if (["select", "multiselect"].includes(input.type) && !input.options) {
        input.options = []
        input.buttonReload = true
      }
      input.loading = false
    })
    this.inputs = JSON.parse(JSON.stringify(fields))
    if (Object.keys(this.projectCredential).length) {
      this.credentials = this.projectCredential.config
      this.isOAuth2Connected = true
      this.searchOptions()
    }
  },

  methods: {
    sendCredential() {
      this.error = false

      if (!Object.keys(this.credentials).length) {
        this.error = true
      }

      if (!this.hiddenInputs && this.inputs.length && this.inputs.filter((f) => f.validation && f.validation.length && f.validation.includes('required') && (f.value == null || f.value == "")).length) {
        this.error = true
      }

      let credentials = JSON.parse(JSON.stringify(this.credentials))
      for (let input of this.inputs) {
        credentials[input.name] = input.value
      }

      this.$emit("saveCredential", credentials, this.error)
    },

    redirect() {
      const url = `${process.env.VUE_APP_URL_API}/client/oauth2/redirect/${this.channel.data_json.driver}?return=${process.env.VUE_APP_URL}/audience/credential-success`
      let component = this
      window.callback = (result) => {
        component.credentials = result
        this.isOAuth2Connected = true
        if (component.inputs.length && !component.hiddenInputs) {
          component.searchOptions()
        } else {
          component.sendCredential()
        }
      }
      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

      const systemZoom = width / window.screen.availWidth
      const left = (width - 700) / 2 / systemZoom
      const top = (height - 800) / 2 / systemZoom
      window.open(url, "", `width=700,height=800,left=${left},top=${top + 50}`)
    },

    async searchOptions() {
      for (const [index, input] of this.inputs.entries()) {
        if(input.options){
          try {
            this.inputs[index].loading = true
            let functionOptions = input?.function ?? input.name
            let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${this.channel.id}/external/data/${functionOptions}`, {
              config: this.credentials
            })
            this.inputs[index].options = res.data
            if(res.data.length == 1){
              this.inputs[index].value = res.data[0][input.keyValue]
            }
            this.inputs[index].loading = false
          } catch (error) {
            //
          }
        }
      }
    },
    async reloadOptions(inputIndex){
      if(Object.keys(this.credentials).length){
        this.inputs[inputIndex].loading = true;
        let functionOptions = this.inputs[inputIndex]?.function ?? this.inputs[inputIndex].name
        let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/channel/${this.channel.id}/external/data/${functionOptions}`, {
          config: this.credentials
        })
        this.inputs[inputIndex].options = res.data
        this.inputs[inputIndex].loading = false
      }
    }
  }
}
</script>
